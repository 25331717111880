import { createStore } from 'vuex';

import detail from './detail.model';

const store = createStore({
    modules: {
        detail
    }
});

export default store;