import fetch from '../helper/fetch';

export default {
    state: {
        logged: false,
        user: {},
        record: ["", "", "", ""]
    },

    mutations: {
        ["user/setInfo"](state, user) {
            state.logged = true;
            state.user = Object.assign(state.user, user);
        },

        ["record/choice"](state, record) {
            state.record[record[0]] = record[1];
            localStorage.setItem("record", JSON.stringify(state.record));
        },

        ["record/set"](state, record) {
            state.record = record;
        }
    },

    actions: {
        async ["user/getInfo"]({ commit }) {
            const { data } = await fetch("user");
            // const appId = "wxa7e828d4c2a3e755";  // navimax
            const appId = "wxb3e8634dba94201a";
            if (data.code != 0) {
                let wechatUrl = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appId}&redirect_uri=https%3A%2F%2Fpafc.navimax.cn%2F&response_type=code&scope=snsapi_userinfo&state=STATE#wechat_redirect`;
                document.location.href = wechatUrl;
                return;
            }

            commit("user/setInfo", data.data);
        },

        ["record/getCache"]({ commit }) {
            let record = localStorage.getItem("record");
            if (record) {
                record = JSON.parse(localStorage.getItem("record"));
                commit("record/set", record);
            }
        },

        ["record/clean"]({ commit }) {
            let record = ["", "", "", ""];
            localStorage.removeItem("record");
            commit("record/set", record);
        },

        async ["share/config"]() {
            const { data } = await fetch("wechat", {
                query: {
                    url: document.location.href,
                },
            });

            const config = data.data;
            const wx = window.wx;

            wx.config(config);
            wx.ready(() => {
                const events = [
                    "updateAppMessageShareData",
                    "updateTimelineShareData",
                    "onMenuShareTimeline",
                    "onMenuShareAppMessage",
                    "onMenuShareQQ",
                    "onMenuShareWeibo",
                    "onMenuShareQZone",
                ];

                events.forEach((event) => {
                    wx[event]({
                        title: "看看我的[爱之超能力]",
                        desc: "两个人的心跳，神秘的爱，解码爱的超能力，抽取好礼",
                        link: "https://pafc.navimax.cn",
                        imgUrl: "https://pafc.navimax.cn/img/home-bg-top.4fa24d3e.png",
                    });
                });
            });
        }
    }
};
