<template>
  <div class="loading" v-if="!logged">
    <img src="./assets/images/loading.png" />
    <p>Loading...</p>
  </div>

  <router-view v-if="logged && user.subscribe" />

  <div class="subscribe" v-if="logged && !user.subscribe">
    <div class="bg"></div>
    <div class="subscribe-modal">
      <div class="subscribe-message">
        <img :src="user.avatar" />
        <p>Hi {{ user.nickname }}</p>
        <p>请长按扫描二维码</p>
        <p>关注公众号后参与测试及抽奖</p>
      </div>
      <img src="./assets/images/qrcode.png" class="qrcode" />
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";

export default {
  name: "App",

  components: {},

  computed: {
    ...mapState({
      logged: (state) => state.detail.logged,
      user: (state) => state.detail.user,
    }),
  },

  watch: {
    "$route.query.code"() {
      if (this.$route.query.code) {
        localStorage.setItem("code", this.$route.query.code);
      }
    },
  },

  data() {
    return {
      code: null,
    };
  },

  mounted() {
    const openId = this.query("open_id");
    if (openId) {
      localStorage.setItem("from_id", openId);
    }

    const self = this;
    setTimeout(self.init, 500);

    this.$store.dispatch("share/config");
  },

  methods: {
    init() {
      this.$store.dispatch("record/getCache");
      this.$store.dispatch("user/getInfo").then(() => {
        this.getRecord();
      });
    },

    async getRecord() {
      const { data } = await this.$fetch("record");

      if (data.code == 0 && data.data.exist) {
        this.$store.commit("record/set", data.data.record);
        this.$router.push("/result");
      }
    },

    query(variable) {
      const query = window.location.search.substring(1);
      const vars = query.split("&");

      for (let i = 0; i < vars.length; i++) {
        let pair = vars[i].split("=");

        if (pair[0] == variable) {
          return pair[1];
        }
      }

      return null;
    },
  },
};
</script>
<style scoped lang="less">
.subscribe {
  width: 100%;
  height: 100%;
  background: url(./assets/images/question-bg.png) no-repeat center / cover;

  .bg {
    width: 100%;
    height: 100%;
    background: rgba(#000, 0.2);
  }

  .subscribe-modal {
    margin: -177px 0 0 -150px;
    width: 300px;
    height: 354px;
    background: url(./assets/images/subscribe.png) no-repeat center / cover;
    position: fixed;
    top: 50%;
    left: 50%;
  }

  .subscribe-message {
    padding-top: 40px;
    height: 110px;
    position: relative;
    text-align: center;

    img {
      width: 70px;
      height: 70px;
      border-radius: 50%;
      display: block;
      object-fit: cover;
      position: absolute;
      top: -35px;
      left: 115px;
    }

    p {
      margin: 0;
      line-height: 24px;
    }
  }

  .qrcode {
    margin: 17px auto;
    width: 120px;
    height: 120px;
    display: block;
  }
}

.loading {
  margin: -70px 0 0 -80px;
  width: 160px;
  height: 140px;
  text-align: center;
  color: #fff;
  background: rgba(#000, 0.5);
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: fixed;
  top: 50%;
  left: 50%;

  img {
    margin-bottom: 5px;
    width: 60px;
    height: 60px;
    animation: turn 1s linear infinite;
  }

  p {
    margin: 10px 0;
  }
}

@keyframes turn {
  0% {
    -webkit-transform: rotate(0deg);
  }
  25% {
    -webkit-transform: rotate(90deg);
  }
  50% {
    -webkit-transform: rotate(180deg);
  }
  75% {
    -webkit-transform: rotate(270deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}
</style>
