import { createRouter, createWebHistory } from "vue-router";

const routes = [
    {
        path: "/",
        component: () => import("./pages/Home")
    },
    {
        path: "/step1",
        component: () => import("./pages/Step1")
    },
    {
        path: "/step2",
        component: () => import("./pages/Step2")
    },
    {
        path: "/step3",
        component: () => import("./pages/Step3")
    },
    {
        path: "/step4",
        component: () => import("./pages/Step4")
    },
    {
        path: "/result",
        component: () => import("./pages/Result")
    }
];

const VueRouter = createRouter({
    history: createWebHistory(),
    routes,
    scrollBehavior() {
        return { top: 0 };
    }
});

export default VueRouter;