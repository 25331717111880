import { createApp } from "vue";

import App from "./App.vue";
import VueRouter from "./router.js";
import Fetch from './helper/fetch';

import store from "./store";

import 'vue3-layer/dist/s3Layer.css';
import { layer } from "vue3-layer";

import "animate.css";

import "./assets/style/global.less";

const app = createApp(App);

app.config.globalProperties.$layer = layer;

app.use(store);
app.use(Fetch);
app.use(VueRouter);

app.mount("#app");