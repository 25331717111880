import CONFIG from '../config';

require('es6-promise').polyfill();
require('isomorphic-fetch');

const environment = {
  api: CONFIG.api
};

let formatUrl = function (url, query) {
  if (!query || !Object.keys(query).length) {
    return url;
  }

  let params = Object.keys(query)
    .filter(x => query[x] !== '')
    .map(x => {
      return `${x}=${query[x]}`;
    })
    .join('&');

  return `${url}?${params}`;
}

let Fetch = (url, option = {}) => {
  let status = 0;

  option.headers = option.headers || {};

  const m = (option.method || 'get').toLocaleLowerCase();
  if (m == 'get') {
    if (option.query) {
      url = formatUrl(url, option.query);
    }
  }

  url += (url.includes("?") === false ? "?" : "&") + "code=" + localStorage.getItem("code");

  if (localStorage.getItem("from_id")) {
    url += (url.includes("?") === false ? "?" : "&") + "_from=" + localStorage.getItem("from_id");
  }


  if (m === 'post' || m === 'put' || m === 'patch') {
    if (url != '/upload') {
      option.headers['Content-Type'] = option.headers['Content-Type'] || 'application/json';
      option.body = JSON.stringify(option.body);
    }
  }

  return new Promise((resolve, reject) => {
    const fetchUrl = environment.api + url;

    fetch(fetchUrl, option)
      .then(response => {
        status = response.status;

        if (response.status >= 500) {
          Fetch.systemError && Fetch.systemError('网络错误，请重试');
        }

        return response;
      })
      .then(parseJSON)
      .then(response => {
        let result = {
          data: response,
          status: status,
          isOk: status == 200
        };

        resolve(result);
      })
      .catch(error => {
        reject({
          message: error,
          state: 400
        });

        // 代码手动取消请求
        if (error.name != "AbortError") {
          Fetch.otherError && Fetch.otherError(error.message);
        }
      });
  });
};

Fetch.install = (app) => {
  app.config.globalProperties.$fetch = Fetch;
};

function parseJSON(response) {
  return response.json();
}

export default Fetch;
